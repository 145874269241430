import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home-five9.component';
import { LoggerService } from './services/logger.service';


export function init_logger(loggerService: LoggerService) {
  return async () => await loggerService.initializeLogger();
}

export function init_framework_config(loggerService: LoggerService) {
  return () => loggerService.initializeFrameworkConfig();
}


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
    ])
  ],
  providers: [LoggerService, {
    provide: APP_INITIALIZER,
    useFactory: init_logger,
    deps: [LoggerService],
    multi: true
  }, {
    provide: APP_INITIALIZER,
    useFactory: init_framework_config,
    deps: [LoggerService],
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
