import { Injectable } from '@angular/core';
import { bind } from 'bind-decorator';
import {
  getPresence,
  setPresence,
  setInteraction,
  logout,
  sendNotification,
  registerOnLogout,
  enableClickToDial,
  registerClickToDial,
  registerOnPresenceChanged,
  RecordItem,
  IInteraction,
  CHANNEL_TYPES,
  NOTIFICATION_TYPE,
  INTERACTION_STATES,
  INTERACTION_DIRECTION_TYPES,
  ICompletedTranscript
} from '@amc-technology/davinci-api';

import { LoggerService } from './logger.service';
import { WSEventTypes } from '../Models/WSEvents.enum';
import { IDetails } from '../Models/IDetails.interface';
import { IRestRequest } from '../Models/IRestRequest.interface';

declare let Five9: any;  // eslint-disable-line

// Refer to the JS SDK docs for all available direction types
const FIVE9_CALL_DIRECTION_MAP = {
  'AGENT': INTERACTION_DIRECTION_TYPES.Outbound,
  'OUTBOUND': INTERACTION_DIRECTION_TYPES.Outbound,
  'OUTBOUND_PREVIEW': INTERACTION_DIRECTION_TYPES.Outbound,
  'OUTBOUND_VOICEMAIL': INTERACTION_DIRECTION_TYPES.Outbound,
  'AGENT_PREVIEW': INTERACTION_DIRECTION_TYPES.Outbound,
  'AUTODIAL': INTERACTION_DIRECTION_TYPES.Outbound,
  'QUEUE_CALLBACK': INTERACTION_DIRECTION_TYPES.Outbound,
  'INBOUND': INTERACTION_DIRECTION_TYPES.Inbound,
  'INBOUND_VOICEMAIL': INTERACTION_DIRECTION_TYPES.Inbound,
  'INTERNAL': INTERACTION_DIRECTION_TYPES.Internal
};


// Ref to REST and WS Events:
// https://webapps.five9.com/assets/files/for_customers/documentation/apis/vcc-agent+supervisor-rest-api-reference-guide.pdf

// Ref to JS SDK:
// https://app.five9.com/dev/sdk/crm/latest/doc/index.html

// TODO: Check if config variables are null
@Injectable({
  providedIn: 'root',
})
export class Five9Service {
  public config: any;
  public agentId: string;
  public baseUrl = '/appsvcs/rs/svc/';
  dispositionCodeToId: any;
  private readonly className = 'Five9Service';
  private interactionApi: any;
  private orgId: string;
  private notReadyIdToReason = {};
  private notReadyReasonToId = {};
  private logoutIdToReason = {};
  private logoutReasonToId = {};
  private interactionState = {};
  private logoutCheckFrequency: number;
  private checkLogoutTimer: any = null;
  private lastReadyChannelsFromFive9: string[] = [];
  private lastReasonCodeFromFive9 = '';
  private lastPresenceSentToFive9: any  = {};
  private interactionSet: Set<string>;
  private isOnInteraction: boolean;
  private onInteractionPresence: string;
  private presenceCheckPoll: any = null;
  private presenceCheckPollIntervalMs = 4000;
  private manualPresenceSetDelayMs = 2000;

  constructor(private logger: LoggerService) {
    this.logoutCheckFrequency = 10000;
    this.isOnInteraction = false;
    this.interactionSet = new Set();
  }
  @bind
  public async setPresenceInFive9(
    presence: string,
    reason?: string,
    initiatingApp?: string
  ): Promise<void> {
    try {
      this.logger.logger.logDebug(
        'Five9Service : setPresenceInFive9 : START'
      );

      if (initiatingApp !== this.config.variables.title) {
        if (Object.keys(this.notReadyReasonToId).length === 0) {
          this.logger.logger.logDebug(
            'Five9Service : getFive9Presence : Not Ready Codes Undefined, Getting Them Now'
          );

          await this.getAllNotReadyReasons();
        }

        let notReadyReasonCodeId: string;
        let readyChannels: string[] = [];
        let ctiPresence: string;
        if (presence === 'Ready' || presence === 'Pending Ready') {
          notReadyReasonCodeId = '0';

          if (reason === null) {
            ctiPresence =
              this.config.variables.DavinciToChannelPresence[presence];
          } else {
            ctiPresence =
              this.config.variables.DavinciToChannelPresence[`${presence}|${reason}`];
          }

          const splittedChannelPresence = ctiPresence.split('|');

          if (splittedChannelPresence.length > 1) {
            readyChannels = splittedChannelPresence[1].split('&');
          }
        } else {
          if (reason === null) {
            notReadyReasonCodeId = this.config.variables.DavinciToChannelPresence[presence];
            ctiPresence = this.config.variables.DavinciToChannelPresence[presence];
          } else {
            notReadyReasonCodeId = this.config.variables.DavinciToChannelPresence[`${presence}|${reason}`];
            ctiPresence = this.config.variables.DavinciToChannelPresence[`${presence}|${reason}`];
          }

          notReadyReasonCodeId = this.notReadyReasonToId[notReadyReasonCodeId.split('|')[1]];
        }
        const stateInfo = {
          notReadyReasonCodeId: notReadyReasonCodeId,
          readyChannels: readyChannels,
        };

        if (this.lastPresenceSentToFive9.notReadyReasonCodeId !== stateInfo.notReadyReasonCodeId ||
          !this.areArraysShallowEqual(stateInfo.readyChannels, this.lastPresenceSentToFive9.readyChannels)) {
          this.lastPresenceSentToFive9 = {
            notReadyReasonCodeId: stateInfo.notReadyReasonCodeId,
            readyChannels: stateInfo.readyChannels
          };

          if (!this.agentId) {
            await this.getAgentId();
          }

          const sendObj: IRestRequest = {
            path: `${this.baseUrl}agents/${this.agentId}/presence`,
            method: 'PUT',
            payload: JSON.stringify(stateInfo),
            contentType: 'application/json',
          };

          this.logger.logger.logDebug(
            'Five9 CTI - Service : five9 : setPresenceInFive9 : END'
          );

          this.sendRestRequest(sendObj);
        } else {
          this.logger.logger.logDebug(
            'Five9 CTI - Service : five9 : setPresenceInFive9 : Current reason code and ready channels are same as last sent, ignoring them : END'
          );
        }
      }

      this.logger.logger.logDebug(
        'Five9Service : setPresenceInFive9 : END'
      );

      return Promise.resolve();
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : setPresenceInFive9 : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  // WS event name: EVENT_PRESENCE_UPDATED
  // WS event ID: 12
  // Happenes when presence changes, does not happen when user logs in
  @bind
  private async handleFive9PresenceChange(params): Promise<void> {
    try {
      this.logger.logger.logDebug(
        'Five9Service : handleFive9PresenceChange : START'
      );

      // TODO: Differentiate for ready and not ready cases, and even logout cases

      if (Object.keys(this.notReadyIdToReason).length === 0) {
        this.logger.logger.logDebug(
          'Five9Service : getFive9Presence : Not Ready Codes Undefined, Getting Them Now'
        );

        await this.getAllNotReadyReasons();
      }

      let readyChannels: string[] = [];
      let reasonCode = '';

      if (params.pendingState) {
        reasonCode = this.notReadyIdToReason[params.pendingState.notReadyReasonCodeId as number];
      } else {
        readyChannels = params.currentState.readyChannels;
        reasonCode = this.notReadyIdToReason[params.currentState.notReadyReasonCodeId as number];
      }

      if (!this.areArraysShallowEqual(readyChannels, this.lastReadyChannelsFromFive9) || reasonCode !== this.lastReasonCodeFromFive9) {
        this.logger.logger.logDebug(
          'Five9 CTI - Service : five9 : handleFive9PresenceChange : END'
        );

        this.lastPresenceSentToFive9 = {
          notReadyReasonCodeId: reasonCode,
          readyChannels: readyChannels
        };

        this.lastReadyChannelsFromFive9 = readyChannels;
        this.lastReasonCodeFromFive9 = reasonCode;

        this.setPresenceInFramework(readyChannels, reasonCode);
      } else {
        this.logger.logger.logDebug(
          'Five9 CTI - Service : five9 : handleFive9PresenceChange : Current reason code and ready channels are same as last sent, ignoring them : END'
        );
      }
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : handleFive9PresenceChange : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  // WS event name: EVENT_LOGIN_STATE_UPDATED
  // WS event ID: 17
  // Happens when login status changes
  @bind
  private async handleLoginStateUpdated(params, context): Promise<void> {
    try {
      this.logger.logger.logDebug(
        'Five9Service : handleLoginStateUpdated : START'
      );

      if (!this.checkLogoutTimer) {
        this.checkLogoutTimer = setInterval(
          this.checkIfLoggedOut,
          this.logoutCheckFrequency
        );
      }

      this.orgId = context.tenantId;
      this.agentId = context.userId;

      await this.getAllNotReadyReasons();
      await this.getAllLogoutReasons();
      this.getFive9Presence();

      this.logger.logger.logDebug(
        'Five9Service : handleLoginStateUpdated : END'
      );
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : handleLoginStateUpdated : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  // WS event name: ServerConnected
  // WS event ID: 1010
  // Happens when a web socket connection to the server created
  @bind
  private async handleServerConnected(params, context): Promise<void> {
    try {
      this.logger.logger.logDebug(
        'Five9Service : handleServerConnected : START'
      );

      if (!this.checkLogoutTimer) {
        this.checkLogoutTimer = setInterval(
          this.checkIfLoggedOut,
          this.logoutCheckFrequency
        );
      }

      this.orgId = context.tenantId;
      this.agentId = context.userId;

      await this.getAllNotReadyReasons();
      await this.getAllLogoutReasons();
      this.getFive9Presence();

      this.logger.logger.logDebug(
        'Five9Service : handleServerConnected : END'
      );
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : handleServerConnected : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  // WS event name: EVENT_CALL_UPDATED
  // WS event ID: 4
  // Happens when call state was updated, or the call ended
  @bind
  private async handleCallUpdated(params): Promise<void> {
    try {
      this.logger.logger.logDebug(
        'Five9Service : handleCallUpdated : START'
      );

      let processInteraction = false;
      let state: INTERACTION_STATES;

      if (params.state === 'ON_HOLD') {
        state = INTERACTION_STATES.OnHold;
        this.interactionState[params.id] = state;
        processInteraction = true;
      } else if (
        params.state === 'TALKING' &&
        this.interactionState[params.id] === INTERACTION_STATES.OnHold
      ) {
        state = INTERACTION_STATES.Connected;
        processInteraction = true;
      }

      if (processInteraction) {
        const CAD_DATA = await this.getCAD(params.id);
        const CAD_OBJECT = {};

        for (const CAD of CAD_DATA) {
          CAD_OBJECT[CAD['name']] = CAD['value'];
        }

        const callParams = {
          interactionId: params.id,
          sessionId: CAD_OBJECT['session_id']
        };

        this.prepareAndSetInteraction(callParams, state, CAD_OBJECT);
      }

      this.logger.logger.logDebug(
        'Five9Service : handleCallUpdated : END'
      );
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : handleCallUpdated : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  @bind
  private async callStarted(params: any): Promise<void> {
    try {
      this.logger.logger.logDebug(
        'Five9Service : callStarted : START'
      );

      const CAD_DATA = await this.getCAD(params.callData.interactionId);

      const state = INTERACTION_STATES.Alerting;
      this.interactionState[params.callData.interactionId] = state;
      const CAD_OBJECT = {};

      for (const CAD of CAD_DATA) {
        CAD_OBJECT[CAD['name']] = CAD['value'];
      }

      for (const CAD in params['callData']) {
        if (!(CAD in CAD_OBJECT)) {
          CAD_OBJECT[CAD] = params['callData'][CAD];
        }
      }

      const callParams = {
        interactionId: params.callData.interactionId,
        sessionId: params.callData.sessionId
      };

      this.prepareAndSetInteraction(callParams, state, CAD_OBJECT);

      this.logger.logger.logDebug(
        'Five9Service : callStarted : END'
      );
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : callStarted : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  @bind
  private async callAccepted(params: any): Promise<void> {
    try {
      this.logger.logger.logDebug(
        'Five9Service : callAccepted : START'
      );

      if (!this.interactionSet.has(params.callData.interactionId)) {
        this.interactionSet.add(params.callData.interactionId);
        this.isOnInteraction = true;

        // TODO: This should happen in the setPresenceInFramework method
        // TODO: Modify setPresenceInFramework to support this
        if (this.interactionSet.size === 1) {
          setPresence(this.onInteractionPresence);
        }
      }

      const CAD_DATA = await this.getCAD(params.callData.interactionId);

      const state = INTERACTION_STATES.Connected;
      this.interactionState[params.callData.interactionId] = state;
      const CAD_OBJECT = {};

      for (const CAD of CAD_DATA) {
        CAD_OBJECT[CAD['name']] = CAD['value'];
      }

      for (const CAD in params['callData']) {
        if (!(CAD in CAD_OBJECT)) {
          CAD_OBJECT[CAD] = params['callData'][CAD];
        }
      }

      const callParams = {
        interactionId: params.callData.interactionId,
        sessionId: params.callData.sessionId
      };

      this.prepareAndSetInteraction(callParams, state, CAD_OBJECT);
      this.logger.logger.logDebug(
        'Five9Service : callAccepted : END'
      );
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : callAccepted : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  @bind
  private async callEnded(params: any): Promise<void> {
    try {
      this.logger.logger.logDebug(
        'Five9Service : callEnded : START'
      );

      const CAD_DATA = await this.getCAD(params.callData.interactionId);

      const state = INTERACTION_STATES.Disconnected;
      this.interactionState[params.callData.interactionId] = state;
      const CAD_OBJECT = {};

      for (const CAD of CAD_DATA) {
        CAD_OBJECT[CAD['name']] = CAD['value'];
      }

      for (const CAD in params['callData']) {
        if (!(CAD in CAD_OBJECT)) {
          CAD_OBJECT[CAD] = params['callData'][CAD];
        }
      }

      const callParams = {
        interactionId: params.callData.interactionId,
        sessionId: params.callData.sessionId
      };

      this.prepareAndSetInteraction(callParams, state, CAD_OBJECT);

      this.logger.logger.logDebug(
        'Five9Service : callEnded : END'
      );
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : callEnded : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  @bind
  private async callFinished(params: any): Promise<void> {
    try {
      this.logger.logger.logDebug(
        'Five9Service : callFinished : START'
      );

      if (!this.interactionSet.has(params.callData.interactionId)) { /* empty */ } else {
        this.interactionSet.delete(params.callData.interactionId);
        if (this.interactionSet.size === 0) {
          this.isOnInteraction = false;
          await this.getFive9Presence();
        }
      }
      const state = INTERACTION_STATES.Disconnected;
      delete this.interactionState[params.callData.interactionId];
      const CAD_OBJECT = {};

      for (const CAD of params['callLogData']['cavList']) {
        CAD_OBJECT[CAD['name']] = CAD['value'];
      }

      for (const CAD in params['callData']) {
        if (!(CAD in CAD_OBJECT)) {
          CAD_OBJECT[CAD] = params['callData'][CAD];
        }
      }

      const callParams = {
        interactionId: params.callData.interactionId,
        sessionId: params.callData.sessionId
      };

      this.prepareAndSetInteraction(callParams, state, CAD_OBJECT);

      this.logger.logger.logDebug(
        'Five9Service : callFinished : END'
      );
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : callFinished : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  @bind
  private async emailOffered(params: any): Promise<void> {
    try {
      this.logger.logger.logDebug(
        'Five9Service : emailOffered : START'
      );

      const state = INTERACTION_STATES.Alerting;
      this.interactionState[params.emailData.interactionId] = state;
      const CAD_OBJECT = {};

      // Email data is of different format than call data
      // Read custom fields, and remove to prevent unnecessary checks in subsequent loop
      if (params.emailData['customFields'] && params.emailData['customFields'].length > 0) {
        for (const CAD of params.emailData['customFields']) {
          CAD_OBJECT[CAD['key']] = CAD['value'];
        }
      }

      delete params.emailData['customFields'];

      for (const CAD in params.emailData) {
        if (params.emailData.hasOwnProperty(CAD)) {
          CAD_OBJECT[CAD] = params.emailData[CAD];
        }
      }

      const emailParams = {
        interactionId: params.emailData.interactionId,
        sessionId: params.emailData.interactionId
      };

      this.prepareAndSetInteraction(emailParams, state, CAD_OBJECT, CHANNEL_TYPES.Email);

      this.logger.logger.logDebug(
        'Five9Service : emailOffered : END'
      );
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : emailOffered : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  @bind
  private async emailAccepted(params: any) {
    try {
      this.logger.logger.logDebug(
        'Five9Service : emailAccepted : START'
      );

      const state = INTERACTION_STATES.Connected;
      this.interactionState[params.emailData.interactionId] = state;
      const CAD_OBJECT = {};

      // Email data is of different format than call data
      // Read custom fields, and remove to prevent unnecessary checks in subsequent loop
      if (params.emailData['customFields'] && params.emailData['customFields'].length > 0) {
        for (const CAD of params.emailData['customFields']) {
          CAD_OBJECT[CAD['key']] = CAD['value'];
        }
      }

      delete params.emailData['customFields'];

      for (const CAD in params.emailData) {
        if (params.emailData.hasOwnProperty(CAD)) {
          CAD_OBJECT[CAD] = params.emailData[CAD];
        }
      }

      const emailParams = {
        interactionId: params.emailData.interactionId,
        sessionId: params.emailData.interactionId
      };

      this.prepareAndSetInteraction(emailParams, state, CAD_OBJECT, CHANNEL_TYPES.Email);

      this.logger.logger.logDebug(
        'Five9Service : emailAccepted : END'
      );
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : emailAccepted : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  @bind
  private async emailFinished(params: any) {
    try {
      this.logger.logger.logDebug(
        'Five9Service : emailFinished : START'
      );

      const state = INTERACTION_STATES.Disconnected;
      this.interactionState[params.emailData.interactionId] = state;
      const CAD_OBJECT = {};

      // Email data is of different format than call data
      // Read custom fields, and remove to prevent unnecessary checks in subsequent loop
      if (params.emailData['customFields'] && params.emailData['customFields'].length > 0) {
        for (const CAD of params.emailData['customFields']) {
          CAD_OBJECT[CAD['key']] = CAD['value'];
        }
      }

      delete params.emailData['customFields'];

      for (const CAD in params.emailData) {
        if (params.emailData.hasOwnProperty(CAD)) {
          CAD_OBJECT[CAD] = params.emailData[CAD];
        }
      }

      // Dispositioning the email creates a log of entire email activity, we can extract some CAD from it
      if (params.emailLogData) {
        // We don't need CRM data
        delete params.emailLogData.who;
        delete params.emailLogData.what;
        delete params.emailLogData.saveLogResult;

        // "comments" is deprecated, use transcript and comment instead
        delete params.emailLogData.comments;

        if (params.emailLogData.disposition) {
          // Disposition data is there, get disposition ID, name and description
          CAD_OBJECT['dispositionId'] = params.emailLogData.disposition.id;
          CAD_OBJECT['dispositionName'] = params.emailLogData.disposition.name;
          CAD_OBJECT['dispositionDescription'] = params.emailLogData.disposition.description;
        } else if (params.emailLogData.dispositionName) {
          // Disposition data is not there, get name
          CAD_OBJECT['dispositionName'] = params.emailLogData.dispositionName;
        }

        // Delete so we can iterate over log data safely
        delete params.emailLogData.disposition;
        delete params.emailLogData.dispositionName;

        for (const CAD in params.emailLogData) {
          if (params.emailLogData.hasOwnProperty(CAD)) {
            CAD_OBJECT[CAD] = params.emailLogData[CAD];
          }
        }
      }

      const emailParams = {
        interactionId: params.emailData.interactionId,
        sessionId: params.emailData.interactionId
      };

      this.prepareAndSetInteraction(emailParams, state, CAD_OBJECT, CHANNEL_TYPES.Email);

      this.logger.logger.logDebug(
        'Five9Service : emailFinished : END'
      );
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : emailFinished : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  @bind
  private async chatOffered(params: any): Promise<void> {
    try {
      this.logger.logger.logDebug(
        'Five9Service : chatOffered : START'
      );

      const state = INTERACTION_STATES.Alerting;
      this.interactionState[params.chatData.interactionId] = state;
      const CAD_OBJECT = {};
      let channel = CHANNEL_TYPES.Chat;

      // Check if chat is SMS or regular chat
      if (params.chatData &&
        params.chatData.mediaSubtype &&
        params.chatData.mediaSubtype.includes('SMS')) {
        delete params.chatData.email;
        channel = CHANNEL_TYPES.SMS;
      }
      // Chat data is of different format than call data
      // Read custom fields, and remove to prevent unnecessary checks in subsequent loop
      if (params.chatData['customFields'] && params.chatData['customFields'].length > 0) {
        for (const CAD of params.chatData['customFields']) {
          CAD_OBJECT[CAD['key']] = CAD['value'];
        }
      }

      delete params.chatData['customFields'];

      for (const CAD in params.chatData) {
        if (params.chatData.hasOwnProperty(CAD)) {
          CAD_OBJECT[CAD] = params.chatData[CAD];
        }
      }

      const chatParams = {
        interactionId: params.chatData.interactionId,
        sessionId: params.chatData.interactionId
      };
      this.prepareAndSetInteraction(chatParams, state, CAD_OBJECT, channel);

      this.logger.logger.logDebug(
        'Five9Service : chatOffered : END'
      );
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : chatOffered : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  @bind
  private async chatAccepted(params: any): Promise<void> {
    try {
      this.logger.logger.logDebug(
        'Five9Service : chatAccepted : START'
      );

      const state = INTERACTION_STATES.Connected;
      this.interactionState[params.chatData.interactionId] = state;
      const CAD_OBJECT = {};
      let channel = CHANNEL_TYPES.Chat;

      // Check if chat is SMS or regular chat
      if (params.chatData &&
        params.chatData.mediaSubtype &&
        params.chatData.mediaSubtype.includes('SMS')) {
        delete params.chatData.email;
        channel = CHANNEL_TYPES.SMS;
      }

      // Chat data is of different format than call data
      // Read custom fields, and remove to prevent unnecessary checks in subsequent loop
      if (params.chatData['customFields'] && params.chatData['customFields'].length > 0) {
        for (const CAD of params.chatData['customFields']) {
          CAD_OBJECT[CAD['key']] = CAD['value'];
        }
      }

      delete params.chatData['customFields'];

      for (const CAD in params.chatData) {
        if (params.chatData.hasOwnProperty(CAD)) {
          CAD_OBJECT[CAD] = params.chatData[CAD];
        }
      }

      const chatParams = {
        interactionId: params.chatData.interactionId,
        sessionId: params.chatData.interactionId
      };

      this.prepareAndSetInteraction(chatParams, state, CAD_OBJECT, channel);
      this.logger.logger.logDebug(
        'Five9Service : chatAccepted : END'
      );
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : chatAccepted : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  @bind
  private async chatEnded(params: any): Promise<void> {
    try {
      this.logger.logger.logDebug(
        'Five9Service : chatEnded : START'
      );

      const state = INTERACTION_STATES.Disconnected;
      this.interactionState[params.chatData.interactionId] = state;
      const CAD_OBJECT = {};
      let channel = CHANNEL_TYPES.Chat;

      // Check if chat is SMS or regular chat
      if (params.chatData &&
        params.chatData.mediaSubtype &&
        params.chatData.mediaSubtype.includes('SMS')) {
        delete params.chatData.email;
        channel = CHANNEL_TYPES.SMS;
      }

      // Chat data is of different format than call data
      // Read custom fields, and remove to prevent unnecessary checks in subsequent loop
      if (params.chatData['customFields'] && params.chatData['customFields'].length > 0) {
        for (const CAD of params.chatData['customFields']) {
          CAD_OBJECT[CAD['key']] = CAD['value'];
        }
      }

      delete params.chatData['customFields'];

      for (const CAD in params.chatData) {
        if (params.chatData.hasOwnProperty(CAD)) {
          CAD_OBJECT[CAD] = params.chatData[CAD];
        }
      }

      const chatParams = {
        interactionId: params.chatData.interactionId,
        sessionId: params.chatData.interactionId
      };

      this.prepareAndSetInteraction(chatParams, state, CAD_OBJECT, channel);

      this.logger.logger.logDebug(
        'Five9Service : chatEnded : END'
      );
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : chatEnded : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  @bind
  private async chatFinished(params: any): Promise<void> {
    try {
      this.logger.logger.logDebug(
        'Five9Service : chatFinished : START'
      );

      const state = INTERACTION_STATES.Disconnected;
      this.interactionState[params.chatData.interactionId] = state;
      const CAD_OBJECT = {};
      let channel = CHANNEL_TYPES.Chat;

      // Check if chat is SMS or regular chat
      if (params.chatData &&
        params.chatData.mediaSubtype &&
        params.chatData.mediaSubtype.includes('SMS')) {
        delete params.chatData.email;
        channel = CHANNEL_TYPES.SMS;
      }

      // Chat data is of different format than call data
      // Read custom fields, and remove to prevent unnecessary checks in subsequent loop
      if (params.chatData['customFields'] && params.chatData['customFields'].length > 0) {
        for (const CAD of params.chatData['customFields']) {
          CAD_OBJECT[CAD['key']] = CAD['value'];
        }
      }

      delete params.chatData['customFields'];

      for (const CAD in params.chatData) {
        if (params.chatData.hasOwnProperty(CAD)) {
          CAD_OBJECT[CAD] = params.chatData[CAD];
        }
      }

      // Dispositioning the chat creates a log of entire chat activity, we can extract some CAD from it
      if (params.chatLogData) {
        // We don't need CRM data
        delete params.chatLogData.who;
        delete params.chatLogData.what;
        delete params.chatLogData.saveLogResult;

        // "comments" is deprecated, use transcript and comment instead
        delete params.chatLogData.comments;

        if (params.chatLogData.disposition) {
          // Disposition data is there, get disposition ID, name and description
          CAD_OBJECT['dispositionId'] = params.chatLogData.disposition.id;
          CAD_OBJECT['dispositionName'] = params.chatLogData.disposition.name;
          CAD_OBJECT['dispositionDescription'] = params.chatLogData.disposition.description;
        } else if (params.chatLogData.dispositionName) {
          // Disposition data is not there, get name
          CAD_OBJECT['dispositionName'] = params.chatLogData.dispositionName;
        }

        // Delete so we can iterate over log data safely
        delete params.chatLogData.disposition;
        delete params.chatLogData.dispositionName;

        for (const CAD in params.chatLogData) {
          if (params.chatLogData.hasOwnProperty(CAD)) {
            CAD_OBJECT[CAD] = params.chatLogData[CAD];
          }
        }
      }

      const chatParams = {
        interactionId: params.chatData.interactionId,
        sessionId: params.chatData.interactionId
      };


      this.prepareAndSetInteraction(chatParams, state, CAD_OBJECT, channel);

      this.logger.logger.logDebug(
        'Five9Service : chatFinished : END'
      );
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : chatFinished : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  @bind
  private async chatRejected(params: any): Promise<void> {
    try {
      this.logger.logger.logDebug(
        'Five9Service : chatRejected : START'
      );

      const state = INTERACTION_STATES.Disconnected;
      this.interactionState[params.chatData.interactionId] = state;
      const CAD_OBJECT = {};
      let channel = CHANNEL_TYPES.Chat;

      // Check if chat is SMS or regular chat
      if (params.chatData &&
        params.chatData.mediaSubtype &&
        params.chatData.mediaSubtype.includes('SMS')) {
        delete params.chatData.email;
        channel = CHANNEL_TYPES.SMS;
      }

      // Chat data is of different format than call data
      // Read custom fields, and remove to prevent unnecessary checks in subsequent loop
      if (params.chatData['customFields'] && params.chatData['customFields'].length > 0) {
        for (const CAD of params.chatData['customFields']) {
          CAD_OBJECT[CAD['key']] = CAD['value'];
        }
      }

      delete params.chatData['customFields'];

      for (const CAD in params.chatData) {
        if (params.chatData.hasOwnProperty(CAD)) {
          CAD_OBJECT[CAD] = params.chatData[CAD];
        }
      }

      const chatParams = {
        interactionId: params.chatData.interactionId,
        sessionId: params.chatData.interactionId
      };

      this.prepareAndSetInteraction(chatParams, state, CAD_OBJECT, channel);

      this.logger.logger.logDebug(
        'Five9Service : chatRejected : END'
      );
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : chatRejected : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  @bind
  private async chatTransferred(params: any): Promise<void> {
    try {
      this.logger.logger.logDebug(
        'Five9Service : chatTransferred : START'
      );

      const state = INTERACTION_STATES.Disconnected;
      this.interactionState[params.chatData.interactionId] = state;
      const CAD_OBJECT = {};
      let channel = CHANNEL_TYPES.Chat;


      // Check if chat is SMS or regular chat
      if (params.chatData &&
        params.chatData.mediaSubtype &&
        params.chatData.mediaSubtype.includes('SMS')) {
        delete params.chatData.email;
        channel = CHANNEL_TYPES.SMS;
      }

      // Chat data is of different format than call data
      // Read custom fields, and remove to prevent unnecessary checks in subsequent loop
      if (params.chatData['customFields'] && params.chatData['customFields'].length > 0) {
        for (const CAD of params.chatData['customFields']) {
          CAD_OBJECT[CAD['key']] = CAD['value'];
        }
      }

      delete params.chatData['customFields'];

      for (const CAD in params.chatData) {
        if (params.chatData.hasOwnProperty(CAD)) {
          CAD_OBJECT[CAD] = params.chatData[CAD];
        }
      }

      const chatParams = {
        interactionId: params.chatData.interactionId,
        sessionId: params.chatData.interactionId
      };

      this.prepareAndSetInteraction(chatParams, state, CAD_OBJECT, channel);

      this.logger.logger.logDebug(
        'Five9Service : chatTransferred : END'
      );
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : chatTransferred : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  @bind
  private async checkIfLoggedOut(): Promise<void> {
    const functionName = 'checkIfLoggedOut';
    try {
      this.logger.logger.logDebug(
        `${functionName} : START`
      );

      const agentLoggedIn = await this.isAgentLoggedIn();

      if (agentLoggedIn === false) {
        this.logoutFromFramework();
        clearInterval(this.checkLogoutTimer);
        this.checkLogoutTimer = null;
      } else if (!agentLoggedIn) {
        this.logger.logger.logTrace(`${functionName} : Failed to determing if user is logged out. agentLoggedIn: ${agentLoggedIn}`);
      }

      this.logger.logger.logDebug(
        'Five9Service : checkIfLoggedOut : END'
      );
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : checkIfLoggedOut : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  @bind
  private async logoutFromFive9(): Promise<void> {
    try {
      this.logger.logger.logDebug(
        'Five9Service : logoutFromFive9 : START'
      );

      const params: IRestRequest = {
        path: `${this.baseUrl}auth/logout`,
        method: 'POST',
        payload: '',
      };
      await this.logger.logger.pushLogsAsync();
      this.sendRestRequest(params);

      this.logger.logger.logDebug(
        'Five9Service : logoutFromFive9 : END'
      );
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : logoutFromFive9 : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  @bind
  private async handleClickToDial(
    phoneNumber: string,
  ): Promise<void> {
    try {
      this.logger.logger.logDebug(
        'Five9Service : handleClickToDial : START'
      );

      this.interactionApi.click2dial({
        click2DialData: { clickToDialNumber: phoneNumber },
      });

      this.logger.logger.logDebug(
        'Five9Service : handleClickToDial : END'
      );

      return Promise.resolve();
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : handleClickToDial : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  public initApi(): void {
    try {
      this.logger.logger.logDebug(
        'Five9Service : initApi : START'
      );

      this.readConfig();

      // TODO: Mention JS library version
      this.interactionApi = Five9.CrmSdk.interactionApi();
      this.registerWSEvents();
      this.registerDavinciEvents();
      this.subscribeToInteractions();

      this.logger.logger.logDebug(
        'Five9Service : initApi : END'
      );
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : initApi : ERROR : ${JSON.stringify(error)}`
      );
    }
  }
  public async startPresenceCheck() {
    try {
      this.logger.logger.logDebug(
        'Five9Service : startPresenceCheck : START'
      );

      if (typeof this.presenceCheckPoll !== 'number') {
        this.presenceCheckPoll = setInterval(async () => {
          const agentLoginState = await this.getAgentLoggedInState();

          if (agentLoginState.status === 200) {
            if (agentLoginState.response.includes('WORKING')) {
              clearInterval(this.presenceCheckPoll);
              this.presenceCheckPoll = null;

              setTimeout(async () => {
                const currentPresence = await getPresence();

                if (currentPresence.presence === 'Pending') {
                  if (!this.checkLogoutTimer) {
                    this.checkLogoutTimer = setInterval(
                      this.checkIfLoggedOut,
                      this.logoutCheckFrequency
                    );
                  }

                  this.getFive9Presence();
                }
              }, this.manualPresenceSetDelayMs);
            }
          } else {
            clearInterval(this.presenceCheckPoll);
            this.presenceCheckPoll = null;

            sendNotification(
              'An error occurred while attempting to connect to Five9, please logout and login again.',
              NOTIFICATION_TYPE.Error
            );
          }
        }, this.presenceCheckPollIntervalMs);
      }

      this.logger.logger.logDebug(
        'Five9Service : startPresenceCheck : END'
      );
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : startPresenceCheck : ERROR : ${JSON.stringify(error)}`
      );
    }
  }
  public async isAgentLoggedIn(): Promise<boolean> {
    const functionName = 'isAgentLoggedIn';
    try {
      this.logger.logger.logDebug(
        `${this.className} : ${functionName} : START`
      );

      const params: IRestRequest = {
        path: `${this.baseUrl}auth/metadata`,
        method: 'GET',
        payload: '',
      };

      const result = await this.sendRestRequest(params);
      this.logger.logger.logTrace(`${this.className} : ${functionName} : Result from Five9. Result ${JSON.stringify(result)}`);

      this.logger.logger.logDebug(
        `${this.className} : ${functionName} : END`
      );

      return result.status !== 401;
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : ${functionName} : ERROR : ${JSON.stringify(error)}}`
      );
    }
  }
  // TODO: Does this need to be public?
  public async getAgentLoggedInState(): Promise<any> {
    try {
      this.logger.logger.logDebug(
        'Five9Service : getAgentLoggedInState : START'
      );

      if (!this.agentId) {
        await this.getAgentId();
      }

      const params: IRestRequest = {
        path: `${this.baseUrl}agents/${this.agentId}/login_state`,
        method: 'GET',
        payload: '',
      };

      const result = await this.sendRestRequest(params);

      this.logger.logger.logDebug(
        'Five9Service : getAgentLoggedInState : END'
      );

      return result;
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : getAgentLoggedInState : ERROR : ${JSON.stringify(error)}`
      );
    }
  }
  // TODO: Create IRestRequest in this function
  public async sendRestRequest(params: IRestRequest): Promise<any> {
    const functionName = 'sendRestRequest';
    try {
      this.logger.logger.logDebug(
        `${this.className} : ${functionName} : START`
      );

      // We need to add a random GUID as a query parameter to work around IE caching
      // Five9 ignores the query parameter so it isn't an issue
      params.path += `?randUUID=${this.uuidv4()}`;

      const response = await this.interactionApi.executeRestApi(params);
      this.logger.logger.logTrace(`${this.className} : ${functionName} : REST Response from Five9: ${JSON.stringify(response)}`);
      return response;
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : ${functionName} : ERROR : ${JSON.stringify(error)}`
      );
    }
  }
  public async getAllDispositionCodes(): Promise<void> {
    try {
      this.logger.logger.logDebug(
        'Five9Service : getAllDispositionCodes : START'
      );

      if (!this.orgId) {
        await this.getOrgId();
      }

      const params: IRestRequest = {
        path: `${this.baseUrl}orgs/${this.orgId}/no_campaign_dispositions`,
        method: 'GET',
        payload: null,
      };

      const result = await this.sendRestRequest(params);
      console.log(result);
      for (const disposition of JSON.parse(result.response)) {
        this.dispositionCodeToId[disposition.name] = disposition.id;
      }

      this.logger.logger.logDebug(
        'Five9Service : getAllDispositionCodes : END'
      );
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : getAllDispositionCodes : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  // Returns true if both arrays are shallow equal (does not check inner levels)
  // Returns false if arrays have pass non primitive types, i.e. obj a is never evaluated equal to obj b
  // Returns false if arrays are shallow different
  private areArraysShallowEqual(a: any[], b: any[]): boolean {
    try {
      this.logger.logger.logDebug(
        'Five9 CTI - Service : five9 : areArraysShallowEqual : START'
      );

      if ((a === undefined && b === undefined) || (a === null && b === null)) {
        this.logger.logger.logDebug(
          'Five9 CTI - Service : five9 : areArraysShallowEqual : Returning True : END'
        );

        return true;
      }

      if (!a || !b) {
        this.logger.logger.logDebug(
          'Five9 CTI - Service : five9 : areArraysShallowEqual : Returning False : END'
        );

        return false;
      }

      if (a.length !== b.length) {
        this.logger.logger.logDebug(
          'Five9 CTI - Service : five9 : areArraysShallowEqual : Returning False : END'
        );

        return false;
      }

      for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
          this.logger.logger.logDebug(
            'Five9 CTI - Service : five9 : areArraysShallowEqual : Returning False : END'
          );

          return false;
        }
      }

      this.logger.logger.logDebug(
        'Five9 CTI - Service : five9 : areArraysShallowEqual : Returning True : END'
      );

      return true;
    } catch (error) {
      this.logger.logger.logError(
        `Five9 CTI - Service : five9 : areArraysShallowEqual : ERROR : ${error.name} : ${error.message}`
      );
    }
  }

  // TODO: Read and check all config variables here
  private readConfig(): void {
    if (this.config.variables['OnInteractionPresence']) {
      this.onInteractionPresence =
        this.config.variables['OnInteractionPresence'];
    } else {
      this.logger.logger.logError(
        `${this.className} : readConfig : ERROR : onInteractionPresence is not set on`
      );
    }

    if (this.config.variables['LogoutCheckFrequency'] != null) {
      this.logoutCheckFrequency = this.config.variables[
        'LogoutCheckFrequency'
      ] as number;
    }
  }

  private async getFive9Presence(): Promise<void> {
    try {
      this.logger.logger.logDebug(
        'Five9Service : getFive9Presence : START'
      );

      if (!this.agentId) {
        await this.getAgentId();
      }

      if (Object.keys(this.notReadyIdToReason).length === 0) {
        this.logger.logger.logDebug(
          'Five9Service : getFive9Presence : Not Ready Codes Undefined, Getting Them Now'
        );

        await this.getAllNotReadyReasons();
      }

      const params: IRestRequest = {
        path: `${this.baseUrl}agents/${this.agentId}/presence`,
        method: 'GET',
        payload: null,
      };

      const result = await this.sendRestRequest(params);
      const response = JSON.parse(result.response);

      const readyChannels: string[] = response.currentState.readyChannels;
      const reasonCodeId: string =
        this.notReadyIdToReason[
        response.currentState.notReadyReasonCodeId as number
        ];

      this.setPresenceInFramework(readyChannels, reasonCodeId);

      this.logger.logger.logDebug(
        'Five9Service : getFive9Presence : END'
      );
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : getFive9Presence : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  private registerWSEvents(): void {
    try {
      this.logger.logger.logDebug(
        'Five9Service : registerWSEvents : START'
      );

      this.interactionApi.subscribeWsEvent(
        WSEventTypes.PRESENCE_UPDATED,
        this.handleFive9PresenceChange
      );
      this.interactionApi.subscribeWsEvent(
        WSEventTypes.LOGIN_STATE_UPDATED,
        this.handleLoginStateUpdated
      );
      this.interactionApi.subscribeWsEvent(
        WSEventTypes.SERVER_CONNECTED,
        this.handleServerConnected
      );
      this.interactionApi.subscribeWsEvent(
        WSEventTypes.EVENT_CALL_UPDATED,
        this.handleCallUpdated
      );

      this.logger.logger.logDebug(
        'Five9Service : registerWSEvents : END'
      );
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : registerWSEvents : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  private registerDavinciEvents(): void {
    try {
      this.logger.logger.logDebug(
        'Five9Service : registerDavinciEvents : START'
      );

      enableClickToDial(true);
      registerOnPresenceChanged(this.setPresenceInFive9);
      registerOnLogout(this.logoutFromFive9);
      registerClickToDial(this.handleClickToDial);

      this.logger.logger.logDebug(
        'Five9Service : registerDavinciEvents : END'
      );
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : registerDavinciEvents : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  private subscribeToInteractions(): void {
    try {
      this.logger.logger.logDebug(
        'Five9Service : subscribeToInteractions : START'
      );

      this.interactionApi.subscribe({
        callStarted: this.callStarted,
        callAccepted: this.callAccepted,
        callEnded: this.callEnded,
        callFinished: this.callFinished,
        emailOffered: this.emailOffered,
        emailAccepted: this.emailAccepted,
        emailFinished: this.emailFinished,
        chatOffered: this.chatOffered,
        chatAccepted: this.chatAccepted,
        chatEnded: this.chatEnded,
        chatFinished: this.chatFinished,
        chatRejected: this.chatRejected,
        chatTransferred: this.chatTransferred,
      });

      this.logger.logger.logDebug(
        'Five9Service : subscribeToInteractions : END'
      );
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : subscribeToInteractions : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  // TODO: Supports either setting to ready, or not ready.
  // TODO: Workmodes such as on an interaction are set directly. Need to refactor this method to do that instead.
  private async setPresenceInFramework(
    readyChannels: string[],
    reasonCode: string
  ) {
    try {
      this.logger.logger.logDebug(
        'Five9Service : setPresenceInFramework : START'
      );

      let presence = '';
      let reason = '';
      let davinciPresence: string;
      let davinciReason = '';

      if (readyChannels.length !== 0) {
        // Agent readied up
        presence = 'Ready';
        reason = readyChannels.sort().join('&');
      } else {
        // Agent went not ready
        presence = 'Not Ready';
        reason = reasonCode;
      }

      let newChannelPresence: string;

      if (!this.isOnInteraction) {
        newChannelPresence = reason ? `${presence}|${reason}` : `${presence}`;
      } else {
        newChannelPresence = reason ? `Pending ${presence}|${reason}` : `Pending ${presence}`;
      }

      if (newChannelPresence in this.config.variables.ChannelToDavinciPresence) {
        davinciPresence = this.config.variables.ChannelToDavinciPresence[newChannelPresence].split('|');
        davinciReason = '';

        if (davinciPresence.length > 1) {
          davinciReason = davinciPresence[1];
        }

        davinciPresence = davinciPresence[0];

        setPresence(davinciPresence, davinciReason);
      }

      this.logger.logger.logDebug(
        'Five9Service : setPresenceInFramework : END'
      );
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : setPresenceInFramework : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  private async getAllLogoutReasons(): Promise<void> {
    try {
      this.logger.logger.logDebug(
        'Five9Service : getAllLogoutReasons : START'
      );

      if (!this.orgId) {
        await this.getOrgId();
      }

      const params: IRestRequest = {
        path: `${this.baseUrl}orgs/${this.orgId}/logout_reason_codes`,
        method: 'GET',
        payload: null,
      };

      const result = await this.sendRestRequest(params);

      for (const presence of JSON.parse(result.response)) {
        this.logoutReasonToId[presence.name] = presence.id;
        this.logoutIdToReason[presence.id] = presence.name;
      }

      this.logger.logger.logDebug(
        'Five9Service : getAllLogoutReasons : END'
      );
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : getAllLogoutReasons : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  private prepareAndSetInteraction(
    params: any,
    state: INTERACTION_STATES,
    CADData: object,
    channel = CHANNEL_TYPES.Telephony
  ): void {
    try {
      this.logger.logger.logDebug('Five9Service : prepareAndSetInteraction : START');

      const CAD_OBJECT: IDetails = {};

      for (const CAD in CADData) {
        if (CADData.hasOwnProperty(CAD)) {
          CAD_OBJECT[CAD] = {
            DevName: '',
            DisplayName: '',
            Value: CADData[CAD],
          };
        }
      }

      // TODO: what about email/chat/sms ?

      const details = new RecordItem('', '', '', CAD_OBJECT);

      if (channel === CHANNEL_TYPES.Telephony) {
        // Queue callback is a special case and we want to treat it as an outbound
        if (CADData['callType'] === 'QUEUE_CALLBACK') {
          details.setPhone('', '', CADData['DNIS']);
        } else {
          let dnis: string;
          let ani: string;
          let agent: string;
          switch (CADData['type_name']) {
          case 'Manual':
            details.setPhone('', '', CADData['DNIS']);
            break;
          case 'Inbound':
            details.setPhone('', '', CADData['ANI']);
            break;
          case 'Internal':
            dnis = CADData['dnis'].replace('agent:', '');
            ani = CADData['ani'].replace('agent:', '');
            agent = CADData['agent'];
            if (agent === ani) {
              details.setPhone('', '', dnis);
            } else {
              details.setPhone('', '', ani);
            }
            break;
          default:
          }
        }
      } else if (channel === CHANNEL_TYPES.SMS) {
        details.setPhone('', '', CADData['phoneNumber']);
      } else if (channel === CHANNEL_TYPES.Email || channel === CHANNEL_TYPES.Chat) {
        details.setEmail('', '', CADData['email']);
      }

      const direction = CADData['callType'] ? FIVE9_CALL_DIRECTION_MAP[CADData['callType']] : INTERACTION_DIRECTION_TYPES.Inbound;
      const newInteraction: IInteraction = {
        interactionId: params.interactionId,
        scenarioId: params.sessionId,
        state: state,
        channelType: channel,
        direction: direction,
        details: details,
      };

      // Add transcript to ICompletedTranscript
      if (CADData['transcript']) {
        const date = new Date();
        const interactionTranscript: ICompletedTranscript = {
          analytics: [],
          id: date.getTime().toString(),
          messages: [CADData['transcript']]
        };
        newInteraction.completedTranscript = interactionTranscript;
      }

      setInteraction(newInteraction);

      this.logger.logger.logDebug('Five9Service : prepareAndSetInteraction : END');
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : prepareAndSetInteraction : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  private async getAllNotReadyReasons(): Promise<void> {
    try {
      this.logger.logger.logDebug(
        'Five9Service : getAllNotReadyReasons : START'
      );

      if (!this.orgId) {
        await this.getOrgId();
      }

      const params: IRestRequest = {
        path: `${this.baseUrl}orgs/${this.orgId}/not_ready_reason_codes`,
        method: 'GET',
        payload: null,
      };

      const result = await this.sendRestRequest(params);

      for (const presence of JSON.parse(result.response)) {
        this.notReadyReasonToId[presence.name] = presence.id;
        this.notReadyIdToReason[presence.id] = presence.name;
      }

      this.logger.logger.logDebug(
        'Five9Service : getAllNotReadyReasons : END'
      );
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : getAllNotReadyReasons : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  private async logoutFromFramework(): Promise<void> {
    try {
      this.logger.logger.logDebug(
        'Five9Service : logoutFromFramework : START'
      );

      await this.logger.logger.pushLogsAsync();
      logout();

      this.logger.logger.logDebug(
        'Five9Service : logoutFromFramework : END'
      );
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : logoutFromFramework : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  private async getCAD(interactionId: string): Promise<any> {
    try {
      this.logger.logger.logDebug(
        'Five9Service : getCAD : QUERY AND RETURN : CAD'
      );
      const result = await this.interactionApi.getCav({
        interactionId: interactionId,
      });
      return result;
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : getCAD : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  private async getAgentId(): Promise<void> {
    try {
      this.logger.logger.logDebug(
        'Five9Service : getAgentId : START'
      );

      const agentMetadata = await this.interactionApi.getMetadata();
      this.agentId = agentMetadata.agentId.toString();

      this.logger.logger.logDebug(
        'Five9Service : getAgentId : END'
      );
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : getAgentId : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  private async getOrgId(): Promise<void> {
    try {
      this.logger.logger.logDebug(
        'Five9Service : getOrgId : START'
      );

      const agentMetadata = await this.interactionApi.getMetadata();
      this.orgId = agentMetadata.tenantId.toString();

      this.logger.logger.logDebug(
        'Five9Service : getOrgId : END'
      );
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : getOrgId : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  // Generates random UUID every time, cannot use uuid NPM package as it is
  // incompatible with IE
  /* eslint-disable no-bitwise */
  private uuidv4(): string {
    this.logger.logger.logDebug(
      'Five9Service : uuidv4 : Generating Random UUID'
    );

    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  /* eslint-enable no-bitwise */
}
