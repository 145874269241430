import {
  CHANNEL_TYPES,
  CONTEXTUAL_OPERATION_TYPE,
  SearchRecords,
  registerClickToAct
} from '@amc-technology/davinci-api';

import { Five9Service } from './five9.service';
import { IRestRequest } from '../Models/IRestRequest.interface';
import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';
import { bind } from 'bind-decorator';

@Injectable({
  providedIn: 'root'
})
export class Five9ClickToAct {
  private readonly className = 'Five9ClickToAct';

  constructor(
    private logger: LoggerService,
    private five9Service: Five9Service
  ) {}

  @bind
  private async handleClickToAct(
    phoneNumber: string,
    records: SearchRecords,
    channelType: CHANNEL_TYPES,
    action: number,
    interactionID: string,
    data: object
  ): Promise<void> {
    const functionName = 'handleClickToAct';
    try {
      this.logger.logger.logDebug(
        'Five9Service : handleClickToAct : START'
      );

      if (action !== null) {
        switch (action) {
        case CONTEXTUAL_OPERATION_TYPE.DTMF:
          // amcUI.dialDTMFDigit(data);
          break;
        case CONTEXTUAL_OPERATION_TYPE.Answer:
          this.answerCall(interactionID);
          break;
        case CONTEXTUAL_OPERATION_TYPE.Reject:
          this.rejectCall(interactionID);
          break;
        case CONTEXTUAL_OPERATION_TYPE.Hangup:
          this.disconnectCall(interactionID);
          break;
        case CONTEXTUAL_OPERATION_TYPE.RemoveParticipant:
          this.removeParticipantFromConference(interactionID);
          break;
        case CONTEXTUAL_OPERATION_TYPE.Cancel:
          // Note: This is not a Five9 action, but a Davinci action
          break;
        case CONTEXTUAL_OPERATION_TYPE.AddParticipant:
          this.addExternalToConference(phoneNumber, interactionID);
          break;
        case CONTEXTUAL_OPERATION_TYPE.Conference:
          // Note: Five9 does not distinguish between a conference and a transfer
          this.addExternalToConference(phoneNumber, interactionID);
          break;
        case CONTEXTUAL_OPERATION_TYPE.WarmTransfer:
          this.completeWarmTransfer(interactionID);
          break;
        case CONTEXTUAL_OPERATION_TYPE.Hold:
          this.holdCall(interactionID);
          break;
        case CONTEXTUAL_OPERATION_TYPE.Unhold:
          this.unholdCall(interactionID);
          break;
        case CONTEXTUAL_OPERATION_TYPE.SetPresence:
          this.setPresenceToFive9({presence: data['presence'], reason: data['reason'], pending: data['pending']});
          break;
        }
      }
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : ${functionName} : handleClickToAct : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  public initApi(): void {
    this.logger.logger.logDebug(
      `${this.className} : initApi : START`
    );

    registerClickToAct(this.handleClickToAct);
  }

  private async answerCall(
    interactionID: string,
  ) {
    const functionName = 'answerCall';
    try {
      this.logger.logger.logDebug(`${this.className} : ${functionName} : START : interactionID : ${interactionID}`);
      const callId = interactionID.replace('DAVINCI APP FOR FIVE9', '');
      const request: IRestRequest = {
        path: `${this.five9Service.baseUrl}agents/${this.five9Service.agentId}/interactions/calls/${callId}/answer`,
        method: 'PUT',
        contentType: 'application/json',
        payload: ''
      };

      const response = await this.five9Service.sendRestRequest(request);
      this.logger.logger.logDebug(`${this.className} : ${functionName} : response : ${JSON.stringify(response)}`);

    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : ${functionName} : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  private async rejectCall(
    interactionID: string
  ) {
    const functionName = 'rejectCall';
    try {
      this.logger.logger.logDebug(`${this.className} : ${functionName} : START : interactionID : ${interactionID}`);
      const callId = interactionID.replace('DAVINCI APP FOR FIVE9', '');
      const request: IRestRequest = {
        path: `${this.five9Service.baseUrl}agents/${this.five9Service.agentId}/interactions/calls/${callId}/reject`,
        method: 'PUT',
        contentType: 'application/json',
        payload: ''
      };

      const response = await this.five9Service.sendRestRequest(request);
      this.logger.logger.logDebug(`${this.className} : ${functionName} : response : ${JSON.stringify(response)}`);

    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : ${functionName} : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  private async disconnectCall(interactionID: string) {
    const functionName = 'disconnectCall';
    try {
      this.logger.logger.logDebug(`${this.className} : ${functionName} : START : interactionID : ${interactionID}`);
      const five9InteractionId = interactionID.replace('DAVINCI APP FOR FIVE9', '');
      const dispostionRequest: IRestRequest = {
        path: `${this.five9Service.baseUrl}agents/${this.five9Service.agentId}/interactions/calls/${five9InteractionId}/dispose`,
        method: 'PUT',
        contentType: 'application/json',
        payload: JSON.stringify({
          'dispositionId': '0'
        })
      };
      const response = await this.five9Service.sendRestRequest(dispostionRequest);
      this.logger.logger.logDebug(`${this.className} : ${functionName} : response : ${JSON.stringify(response)}`);
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : ${functionName} : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  private async holdCall(interactionID: string) {
    const functionName = 'holdCall';
    try {
      this.logger.logger.logDebug(`${this.className} : ${functionName} : START : interactionID : ${interactionID}`);
      const five9InteractionId = interactionID.replace('DAVINCI APP FOR FIVE9', '');
      const request: IRestRequest = {
        path: `${this.five9Service.baseUrl}agents/${this.five9Service.agentId}/interactions/calls/${five9InteractionId}/hold`,
        method: 'PUT',
        contentType: 'application/json',
        payload: ''
      };
      const response = await this.five9Service.sendRestRequest(request);
      this.logger.logger.logDebug(`${this.className} : ${functionName} : response : ${JSON.stringify(response)}`);
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : ${functionName} : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  private async unholdCall(interactionID: string) {
    const functionName = 'unholdCall';
    try {
      this.logger.logger.logDebug(`${this.className} : ${functionName} : START : interactionID : ${interactionID}`);
      const callId = interactionID.replace('DAVINCI APP FOR FIVE9', '');
      const request: IRestRequest = {
        path: `${this.five9Service.baseUrl}agents/${this.five9Service.agentId}/interactions/calls/${callId}/unhold`,
        method: 'PUT',
        contentType: 'application/json',
        payload: ''
      };
      const response = await this.five9Service.sendRestRequest(request);
      this.logger.logger.logDebug(`${this.className} : ${functionName} : response : ${JSON.stringify(response)}`);
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : ${functionName} : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  private async addExternalToConference(phoneNumber: string, interactionID: string) {
    const functionName = 'addExternalToConference';
    try {
      this.logger.logger.logDebug(`${this.className} : ${functionName} : START : interactionID : ${interactionID}`);
      const five9InteractionId = interactionID.replace('DAVINCI APP FOR FIVE9', '');
      const request: IRestRequest = {
        path: `${this.five9Service.baseUrl}agents/${this.five9Service.agentId}/interactions/calls/${five9InteractionId}/add_external_to_conference`,
        method: 'POST',
        contentType: 'application/json',
        payload: JSON.stringify({
          'number': phoneNumber
        })
      };
      const response = await this.five9Service.sendRestRequest(request);
      console.log(response);
      this.logger.logger.logDebug(`${this.className} : ${functionName} : response : ${JSON.stringify(response)}`);
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : ${functionName} : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  // Todo: Multiparty State events outside of addExternal cannot be completed
  // until Five9 Implements multiparty state as part of setInteraction
  private async removeParticipantFromConference(interactionID: string) {
    const functionName = 'removeParticipantFromConference';
    try {
      this.logger.logger.logDebug(`${this.className} : ${functionName} : START : interactionID : ${interactionID}`);
      const five9InteractionId = interactionID.replace('DAVINCI APP FOR FIVE9', '');
      const request: IRestRequest = {
        path: `${this.five9Service.baseUrl}agents/${this.five9Service.agentId}/interactions/calls/${five9InteractionId}/remove_participant_from_conference`,
        method: 'PUT',
        contentType: 'application/json',
        payload: ''
      };
      const response = await this.five9Service.sendRestRequest(request);
      console.log(response);

      this.logger.logger.logDebug(`${this.className} : ${functionName} : response : ${JSON.stringify(response)}`);
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : ${functionName} : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  private async completeWarmTransfer(interactionID: string) {
    const functionName = 'completeWarmTransfer';
    try {
      this.logger.logger.logDebug(`${this.className} : ${functionName} : START : interactionID : ${interactionID}`);
      const five9InteractionId = interactionID.replace('DAVINCI APP FOR FIVE9', '');
      const request: IRestRequest = {
        path: `${this.five9Service.baseUrl}agents/${this.five9Service.agentId}/interactions/calls/${five9InteractionId}/complete_warm_conference`,
        method: 'POST',
        contentType: 'application/json',
        payload: ''
      };
      const response = await this.five9Service.sendRestRequest(request);
      this.logger.logger.logDebug(`${this.className} : ${functionName} : response : ${JSON.stringify(response)}`);
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : ${functionName} : ERROR : ${JSON.stringify(error)}`
      );
    }
  }

  private async setPresenceToFive9(data: {presence: string; reason: string; pending: string}) {
    const functionName = 'setPresence';
    try {
      this.logger.logger.logDebug(`${this.className} : ${functionName} : START : data : ${JSON.stringify(data)}`);
      // TODO: add pending
      const response = await this.five9Service.setPresenceInFive9(data['presence'], data['reason'], 'ClickToAct');
      this.logger.logger.logDebug(`${this.className} : ${functionName} : response : ${JSON.stringify(response)}`);
    } catch (error) {
      this.logger.logger.logError(
        `${this.className} : ${functionName} : ERROR : ${JSON.stringify(error)}`
      );
    }
  }
}
