import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
  initializeComplete,
  setAppHeight
} from '@amc-technology/davinci-api';

import { Five9ClickToAct } from '../services/Five9ClickToAct.service';
import { Five9Service } from '../services/five9.service';
import { LoggerService } from '../services/logger.service';

@Component({
  selector: 'app-home',
  templateUrl: './home-five9.component.html',
  styleUrls: ['./home-five9.component.css']
})
export class HomeComponent implements OnInit {
  config: any;
  iFrameURL: SafeResourceUrl;
  iFrameStyles: object;
  initComplete = false;
  private readonly className = 'HomeComponent';

  constructor(
    private five9Service: Five9Service,
    private five9ClickToAct: Five9ClickToAct,
    private sanitizer: DomSanitizer,
    private logger: LoggerService
  ) {}

  async ngOnInit() {
    const functionName = 'ngOnInit';
    try {
      this.config = this.logger.frameworkConfig;
      this.logger.logger.logDebug(`${this.className} : ${functionName} : START`);

      this.iFrameStyles = {
        'position': 'relative',
        'height': this.config.variables.AppHeight + 'px',
        'width': '100%',
        'border': '0'
      };

      this.iFrameURL = this.sanitizer.bypassSecurityTrustResourceUrl(
        `${this.config.variables.Five9URL}?f9verticalthreshold=${this.config.variables.AppHeight}px`);

      this.five9Service.config = this.config;

      setAppHeight(this.config.variables.AppHeight);
      this.five9Service.initApi();
      this.five9ClickToAct.initApi();
      initializeComplete(this.logger.logger);
      this.initComplete = true;

      this.logger.logger.logDebug(`${this.className} : ${functionName} : END`);
    } catch (error) {
      this.logger.logger.logError(`${this.className} : ${functionName} : ERROR: ${JSON.stringify(error)}`);
    }
  }

  async iFrameLoaded() {
    const functionName = 'iFrameLoaded';
    try {
      this.logger.logger.logDebug(`${this.className} : ${functionName} : Checking connection to Five9. Logged In: ${this.initComplete}`);
      if (this.initComplete && await this.five9Service.isAgentLoggedIn()) {
        this.five9Service.startPresenceCheck();
      }
    } catch (error) {
      this.logger.logger.logError(`${this.className} : ${functionName} : Unable to check if frame is loaded. Error: ${JSON.stringify(error)}`);
    }
  }
}
